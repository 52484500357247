import { get, post, put } from 'apiClient/apiCalls';
import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';

// fetch a plan
export const getPlan = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/get-plan/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteCompPlan = async (id) => {
  // API call to delete a plan
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/manage-plans/delete-compensation-plan/${id}`,
      {},
    );
    return response?.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const archivePlan = async (operation, planId) => {
  let URL = `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/manage-plans/archived-compensation-plan/${planId}`;
  try {
    if (operation === 'archive') {
      URL += '?status=archived';
    }
    const response = await post(URL, {});
    return response?.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const duplicatePlan = async (body, planId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/duplicate-plan/${planId}`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
// create a new plan
export const createNewPlan = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/create-plan`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const checkIfPlanIsLock = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/forms/lock/${planId}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const updatePlanDetails = async (body, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/edit-plan/${id}`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// create plan settings

export const updatePlanSetting = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/plan-setting`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const updatePlanName = async (body, planId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/update-plan-type-labels/${planId}`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllInputFields = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/${CONSTANT.MASTER}/employee-fields`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInputFieldCriteria = async (type, id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V2}/get-operators/${type}/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getInputFieldValues = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/master/employee-field-dropdown/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// update eligibilty group
export const updateEligliblity = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/plan-eligliblity`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
// get eligibilty

export const getEligiblityGroup = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/get-eligliblity-format`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getDataSources = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/column-design/get-data-sources`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
// get Eligible users

export const getEligibleUsers = async (
  planId,
  planType,
  search,
  pageIndex,
  pageSize,
) => {
  try {
    let URL = `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/get-eligible-users/${planId}?`;
    if (planType) {
      URL += `&planType=${planType}`;
    }
    if (search) {
      URL += `&search=${search}`;
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      URL += `&pageIndex=${pageIndex}`;
    }
    if (pageSize !== undefined && pageSize !== null) {
      URL += `&pageSize=${pageSize}`;
    }
    const response = await get(URL);
    return {
      data: {
        data: response?.data?.eligibleUsers,
        total: response?.data?.pagination?.totalCount,
      },
    };
  } catch (error) {
    throw new Error(error);
  }
};
export const getPlanInfo = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/get-philosophy-letter/${planId}`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const checkApproverAccess = async (planId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/worksheets/approver-access/${planId}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getBudgetLabels = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/get-plan-type-labels/${planId}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const togglePhilosophy = async (planId, body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/show-philosophy-letter/${planId}`,
      body,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getColorsForColumnDesign = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/column-design/get-all-colors`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMasterSourcesForDropDown = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/column-design/get-master-sources`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllWorkFlow = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_WORKFLOW_V2}/workflow/get-all-workflows?category=CompensationPlan&view=inactive`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAWorkFlow = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_WORKFLOW_V2}/workflow/get-workflow/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllColumns = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/compensation-v3/column-design/get-all-columns/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllAmountColumns = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/compensation-v3/column-design/get-amount-columns/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getFlagsAsDataSourceForColumn = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/compensation-v3/column-design/flags-dropdown`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getColumnDropDown = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/compensation-v3/column-design/get-column-dropdown/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateColumnValidations = async (body, planId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/compensation-v3/column-design/update/validations/${planId}`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateColumnPermissions = async (body, planId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/compensation-v3/column-design/update/permissions/${planId}`,
      body,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// update Workflow
export const updateWorkFlow = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/plan-workflow`,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllPlans = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_PLANS_URL}/get-all-compensation-plans`,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllWorksheetPlans = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_PLANS_URL}/get-all-compensation-worksheet-plans`,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllPlanSubData = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.COMPENSATION_PLANS_URL}/get-compensation-plan-subType/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const exportBudgetSummary = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/upload-download/budget-summary-download `,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
export const getGuidelinesPlan = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_PLAN}/get-guideline`,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMasterColumn = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/column-design/get-master-columns/${planId}`,
    );
    const options = response?.data?.map((ele) => ({
      id: ele.id,
      label: ele.values.settings.name,
      value: ele.values.settings.name,
    }));
    return options;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMasterColumnDropdown = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/column-design/get-column-dropdown/${planId}`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const saveGuidelinePlan = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/plan-guideline`,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllCurrency = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_CURRENCY}/get-all-currency`,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getConversionRate = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_CURRENCY}/get-conversion-rate`,
      payload,
    );
    return response?.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getDefaultCurrency = async () => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_CURRENCY}/get-all-currency`,
      {
        view: {
          isDefault: true,
        },
      },
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCurrencyByPlanId = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_CURRENCY}/get-currency-by-plan-id`,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const saveCurrency = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_CURRENCY}/save-currency`,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateCurrency = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_CURRENCY}/update-currency`,
      payload,
    );
    return response?.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getSettings = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.COMPENSATION_PLAN}/get-active-setting/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getColumnDesign = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/compensation-v3/column-design/get-columns/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAmountColumns = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/column-design/get-amount-columns/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBudgets = async (id, type) => {
  try {
    const response = await get(
      `${CONSTANT.COMPENSATION_BUDGET}/get/${id}/${type}`,
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const saveBudget = async (payload) => {
  try {
    const response = await put(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/budget/update`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.response?.data);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const updateBudgetType = async (planId, type) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_BUDGET}/update/${planId}/${type}`,
    );
    return response?.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const updateAdditionalBudget = async (planId, payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_BUDGET}/summary/additional-budget/${planId}`,
      payload,
    );

    return response?.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getSummaryPlanner = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.COMPENSATION_BUDGET}/salary-planners/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// get budget summary with details

export const getBudgetDetails = async (planId, pageIndex, pageSize, search) => {
  let URL = `${CONSTANT.COMPENSATION_BUDGET}/summary/get/${planId}`;
  //   if (search) {
  //     URL += `&search=${search}`;
  //   }
  try {
    const response = await post(URL, { search: search });
    return response?.data;
  } catch (error) {
    return error;
  }
};

// refresh budget API

export const getRefreshedBudget = async (planId) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_BUDGET}/summary/refresh/${planId}`,
      {},
    );
    return response?.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getBudgetSummary = async (payload, id) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_BUDGET}/summary/${id}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// / update column desgin

export const updateColumnDesign = async (planId, payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/column-design/update/${planId}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// get column design  by plan id

export const getColumnDesignByPlanId = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/column-design/get-by-plan-id/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// refresh Employee Data

export const refreshEmployeeData = async (id, type, body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/employee-data/${type}/${id}`,
      body,
    );
    return response?.data;
  } catch (err) {
    console.log(err);
    throw new AxiosError('Failed to refresh employee data', err.response);
    // if (err) {

    //   throw new AxiosError(err.message);
    // } else {
    //   throw new Error("Error! employee by authId fetch failed");
    // }
  }
};
//batch refresh
export const refreshBatchEmployeeData = async (id, type, body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/employee-data/batch-refresh/${id}`,
      body,
    );
    return response?.data;
  } catch (err) {
    console.log(err);
    throw new AxiosError('Failed to refresh employee data', err.response);
    // if (err) {

    //   throw new AxiosError(err.message);
    // } else {
    //   throw new Error("Error! employee by authId fetch failed");
    // }
  }
};
export const getBudgetDownload = async (id, type, subType) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/budget/get-budget-csv/${id}/${type}/${subType}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const getGuidelineDownload = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/get-guideline-downloads`,
      payload,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const getEligibleGroupUserDownload = async (id, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/compensation-plan/get-eligible-users-download/${id}?planType=${type}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const getGuidelineUpload = async (payload, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/upload-download/upload-guideline`,
      payload,
      type,
    );
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getBudgetUpload = async (payload, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/upload-download/upload-budget`,
      payload,
      type,
    );
    return response;
  } catch (err) {
    throw new Error(err?.response?.data);
  }
};

export const getPaymixEsop = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.COMPENSATION_2A}/get-paymix-esop`,
      payload,
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};
export const downloadEmployeeData = async (planId, body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/upload-download/employee-data-download/${planId}`,
      body,
    );
    return response;
  } catch (error) {
    throw error?.response?.data;
  }
};
// upload Bulk user in eligibility
export const uploadBulkUserInEligibility = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/upload-download/upload-eligibleGroup`,
      payload,
      'multipart/form-data',
    );
    return response?.data;
  } catch (error) {
    throw error?.response?.data;
  }
};
export const downloadBulkUserInEligibility = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/upload-download/download-eligibleGroup`,
      payload,
      'multipart/form-data',
    );
    return response;
  } catch (error) {
    throw error?.response?.data;
  }
};
//download workflow data

export const downloadWorkflowData = async (planId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/upload-download/workflow-download/${planId}`,
    );
    return response;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const downloadWorkflowTemplate = async (planId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/upload-download/workflow-template-download/${planId}`,
    );
    return response;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const uploadWorkflowData = async (planId, body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V2}/upload-download/workflow-template-upload/${planId}`,
      body,
      'multipart/form-data',
    );
    return response;
  } catch (error) {
    throw error?.response?.data;
  }
};
